<template lang="html">
  <div>
    <div class="informationPerfect mainBox">
      <div class="box">
        <div class="title">
          资料完善
        </div>
        <div class="content" v-if="isShow == 1">
          <div class="list">
            <span class="name">企业名称</span>
            <div class="accout">
              <input type="text" v-model="params.userName" placeholder="输入企业名称"/>
            </div>
          </div>
          <div class="list">
            <span class="name">姓名</span>
            <div class="accout">
              <input type="text" v-model="params.nickName" placeholder="联系人姓名"/>
            </div>
          </div>
          <div class="list">
            <span class="name">联系电话</span>
            <div class="accout">
              <input type="text" v-model="params.userPhone" placeholder="联系电话"/>
            </div>
          </div>
          <div class="list">
            <span class="name">统一社会信用代码</span>
            <div class="accout">
              <input type="text" v-model="params.creditCode" placeholder="统一社会信用代码"/>
            </div>
          </div>
          <div class="list">
            <span class="name">上传营业执照</span>
            <div class="fileBox">
              <input type="file" ref="businessLicense" @change="businessLicense">
              <img :src="params.cloudBusiness" class="imgBox" v-if="params.cloudBusiness" alt="">
              <div v-else>
                <img src="../assets/img/fileIcon.png" alt="">
                <p>点击上传营业执照</p>
              </div>
            </div>
          </div>
          <div class="list">
            <span class="name">上传经营许可证</span>
            <div class="fileBox">
              <input type="file" ref="otherCardChange" @change="otherCardChange">
              <img :src="params.otherCard" class="imgBox" v-if="params.otherCard" alt="">
              <div v-else>
                <img src="../assets/img/fileIcon.png" alt="">
                <p>点击上传经营许可证</p>
              </div>
            </div>
          </div>
          <!-- <div class="list">
        <span class="name">上传LOGO</span>
        <div class="logoBox">
          <input type="file" ref="logoChange" @change="logoChange">
          <img :src="params.userLogo" class="imgBox" v-if="params.userLogo" alt="">
          <div v-else>
            <img src="../assets/img/fileIcon.png" alt="">
            <p>点击上传经营许可证</p>
          </div>
        </div>
      </div> -->
          <div class="btn" @click="affirm">
            <button type="button">提交</button>
          </div>
        </div>
        <div class="content" v-if="isShow == 2">
          <div class="list">
            <span class="name">姓名</span>
            <div class="accout">
              <input type="text" v-model="params.nickName" placeholder="姓名"/>
            </div>
          </div>
          <div class="list">
            <span class="name">联系电话</span>
            <div class="accout">
              <input type="text" v-model="params.userPhone" placeholder="联系电话"/>
            </div>
          </div>
          <div class="list">
            <span class="name">联系地址</span>
            <div class="accout">
              <input type="text" v-model="params.userAddress" placeholder="联系地址"/>
            </div>
          </div>
          <div class="list">
            <span class="name">身份证号码</span>
            <div class="accout">
              <input type="text" v-model="params.userIdCode" placeholder="身份证号码"/>
            </div>
          </div>
          <div class="list">
            <span class="name">上传身份证正面</span>
            <div class="fileBox">
              <input type="file" ref="idCardJust" @change="idCardJustChange">
              <img :src="params.idCardJust" class="imgBox" v-if="params.idCardJust" alt="">
              <div v-else>
                <img src="../assets/img/fileIcon.png" alt="">
                <p>点击上传身份证正面</p>
              </div>
            </div>
          </div>
          <div class="list">
            <span class="name">上传身份证反面</span>
            <div class="fileBox">
              <input type="file" ref="idCardBack" @change="idCardBackChange">
              <img :src="params.idCardBack" class="imgBox" v-if="params.idCardBack" alt="">
              <div v-else>
                <img src="../assets/img/fileIcon.png" alt="">
                <p>点击上传身份证反面</p>
              </div>
            </div>
          </div>
          <div class="btn" @click="affirm">
            <button type="button">提交</button>
          </div>
        </div>
      </div>

    </div>
    <myBase></myBase>
  </div>


</template>

<script>
import myBase from '@/components/myBase.vue';

export default {
  name: 'informationPerfect',
  components: {
    myBase,
  },
  data() {
    return {
      isShow: 1,
      userInfo: window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null,
      params: {
        userName: '',
        nickName: '',
        userPhone: '',
        creditCode: '',
        cloudBusiness: '',
        otherCard: '',
        userAddress: '',
        userIdCode: '',
        idCardJust: '',
        idCardBack: '',
      },
    };
  },
  created() {
    if (this.userInfo) {
      if (this.userInfo.user.userType == 10) {
        this.isShow = 2;
      } else {
        this.isShow = 1;
      }
      this.params.userName = this.userInfo.user.userName;
      this.params.nickName = this.userInfo.user.nickName;
      this.params.userPhone = this.userInfo.user.userPhone;
      this.params.creditCode = this.userInfo.user.creditCode;
      this.params.cloudBusiness = this.userInfo.user.cloudBusiness;
      this.params.otherCard = this.userInfo.user.otherCard;
      this.params.userAddress = this.userInfo.user.userAddress;
      this.params.userIdCode = this.userInfo.user.userIdCode;
      this.params.idCardJust = this.userInfo.user.idCardJust;
      this.params.idCardBack = this.userInfo.user.idCardBack;
    }
  },
  methods: {
    affirm() {
      if (!this.params.nickName) {
        this.$toast('请输入姓名');
        return;
      }
      if (!this.params.userPhone) {
        this.$toast('请输入联系电话');
        return;
      }
      if (this.isShow == 1) {
        if (!this.params.userName) {
          this.$toast('请输入企业名称');
          return;
        }
        if (!this.params.creditCode) {
          this.$toast('请输入统一社会信用代码');
          return;
        }
        if (!this.params.cloudBusiness) {
          this.$toast('请上传营业执照');
          return;
        }
        if (!this.params.otherCard) {
          this.$toast('请上传经营许可证');
          return;
        }
      } else {
        if (!this.params.userAddress) {
          this.$toast('请输入联系地址');
          return;
        }
        if (!this.params.userIdCode) {
          this.$toast('请输入身份证号码');
          return;
        }
        if (!this.params.idCardJust) {
          this.$toast('请上传身份正面');
          return;
        }
        if (!this.params.idCardBack) {
          this.$toast('请上传身份反面');
          return;
        }
      }
      this.$request.HttpPost('/api/userPerfect', this.params).then((res) => {
        this.$router.push({path: '/personalCenter'});
      });
    },
    businessLicense(event) {
      let file = this.$refs.businessLicense;
      if (file && file.files.length > 0) {
        let formDate = new FormData();
        formDate.append('file', file.files[0]);
        this.$request.HttpPost('/api/upload', formDate).then(res => {
          this.params.cloudBusiness = process.env.VUE_APP_URL_IMG + res.data.file.url;
        });
      }
    },
    otherCardChange(event) {
      let file = this.$refs.otherCardChange;
      if (file && file.files.length > 0) {
        let formDate = new FormData();
        formDate.append('file', file.files[0]);
        this.$request.HttpPost('/api/upload', formDate).then(res => {
          this.params.otherCard = process.env.VUE_APP_URL_IMG + res.data.file.url;
        });
      }
    },
    idCardJustChange(event) {
      let file = this.$refs.idCardJust;
      if (file && file.files.length > 0) {
        let formDate = new FormData();
        formDate.append('file', file.files[0]);
        this.$request.HttpPost('/api/upload', formDate).then(res => {
          this.params.idCardJust = process.env.VUE_APP_URL_IMG + res.data.file.url;
        });
      }
    },
    idCardBackChange(event) {
      let file = this.$refs.idCardBack;
      if (file && file.files.length > 0) {
        let formDate = new FormData();
        formDate.append('file', file.files[0]);
        this.$request.HttpPost('/api/upload', formDate).then(res => {
          this.params.idCardBack = process.env.VUE_APP_URL_IMG + res.data.file.url;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.informationPerfect {
  .box {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .title {
      font-size: 14px;
      color: #333333;
      position: relative;
      height: 20px;
      margin-right: 20px;
      margin-top: 44px;

      &:before {
        content: ' ';
        position: absolute;
        width: 3px;
        height: 14px;
        background: #3D7EFF;
        top: 50%;
        transform: translateY(-50%);
        left: -8px;
      }
    }

    .content {
      width: 800px;
      min-height: 670px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
      padding-top: 30px;
      box-sizing: border-box;

      .list {
        display: flex;
        margin-bottom: 10px;

        span {
          display: block;
          width: 200px;
          margin-right: 20px;
          text-align: right;
          font-size: 12px;
          color: #666666;
          height: 42px;
          padding-top: 15px;
          box-sizing: border-box;
        }

        .accout {
          width: 360px;
          height: 42px;

          box-sizing: border-box;
          background: #FFFFFF;
          border: 1px solid #C8C8C8;
          padding-left: 20px;
          input{
            width: 100%;
            height: 100%;
            line-height: 42px;
          }
        }

        .fileBox {
          width: 360px;
          height: 152px;
          background: #FFFFFF;
          border: 1px dashed #C8C8C8;
          text-align: center;
          position: relative;

          input {
            flex: 1;
            height: 152px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            opacity: 0;
            cursor: pointer;
          }

          .imgBox {
            width: 360px;
            height: 152px;
            padding: 0;
            object-fit: cover;
          }

          img {
            display: block;
            margin: 0 auto;
            width: 48px;
            height: 48px;
            padding-top: 40px;
          }

          p {
            font-size: 12px;
            color: #C8C8C8;
            margin-top: 14px;
          }
        }

        .logoBox {
          width: 120px;
          height: 80px;
          background: #FFFFFF;
          border: 1px dashed #C8C8C8;
          text-align: center;
          position: relative;

          input {
            flex: 1;
            height: 80px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            opacity: 0;
            cursor: pointer;
          }

          .imgBox {
            width: 120px;
            height: 80px;
            padding: 0;
            object-fit: cover;
          }

          img {
            display: block;
            margin: 0 auto;
            width: 32px;
            height: 32px;
            padding-top: 15px;
          }

          p {
            font-size: 12px;
            color: #C8C8C8;
            margin-top: 10px;
          }
        }
      }

      .btn {
        width: 360px;
        height: 42px;
        margin-top: 30px;

        button {
          display: block;
          width: 360px;
          height: 42px;
          background: #3D7EFF;
          cursor: pointer;
          color: #FFFFFF;
          margin-left: 220px;
        }
      }
    }
  }

}
input{
  flex: 1;
}

</style>
